export const enum API {
  // config
  PROJECT_CONFIG = '/project/config',
  // activity track
  ACTIVITY_TRACK = '/project/activity/track',
  // campaign
  METRIC_LIST = '/metric/list',
  PERFORMANCE_OVERVIEW_CARDS = '/report/performance_overview/cards',
  PERFORMANCE_OVERVIEW_TREND = '/report/performance_overview/trend',
  CAMPAIGN_LIST = '/campaign/campaign_list',
  APAC_CAMPAIGN_LIST = '/campaign/apac_campaign_list',
  APAC_CAMPAIGN_BUDGET = '/campaign/apac_campaign_budget',
  APAC_CAMPAIGN_EXPORT_TEMPLATE = '/campaign/apac_campaign_export_template',
  APAC_CAMPAIGN_IMPORT = '/campaign/apac_campaign_import',
  APAC_CAMPAIGN_NAME_LIST = '/campaign/apac_campaign_name_list',
  APAC_CAMPAIGN_STATUS_TARGET_LIMIT = '/campaign/apac_campaign_status_target_limit',
  APAC_CAMPAIGN_TARGET_LIMIT = '/campaign/apac_campaign_target_limit',
  APAC_CAMPAIGN_PERFORMANCE_OVERVIEW = '/campaign/apac_campaign_performance_overview',
  APAC_CAMPAIGN_PERFORMANCE_CHART = '/campaign/apac_campaign_performance_chart',
  APAC_KEYWORD_LIST = '/v3/campaign/keyword/shopee/list',
  APAC_KEYWORD_LAST_UPDATED_AT = '/v3/campaign/keyword/shopee/get-last-updated-at',
  APAC_SKU_LIST = '/v2/campaign/sku/shopee/list',
  GET_FILTER_TAGS_LIST = '/v2/campaign/sku/shopee/tag-list',
  APAC_EXPORT_SKU_TAG = '/v2/campaign/sku/shopee/download-tag-template',
  APAC_IMPORT_SKU_TAG = '/v2/campaign/sku/shopee/bulk-import-tags',
  GET_FILTER_KEYWORD_TAGS_LIST = '/v2/campaign/keyword/shopee/tag-list',
  APAC_EXPORT_KEYWORD_TAG = '/v2/campaign/keyword/shopee/download-tag-template',
  APAC_IMPORT_KEYWORD_TAG = '/v2/campaign/keyword/shopee/bulk-import-tags',
  GET_ACTIVE_SKU_TAG_LIST = '/v3/campaign/sku/shopee/list-active-tag',
  GET_ACTIVE_KEYWORD_TAG_LIST = '/v3/campaign/keyword/shopee/list-active-tag',
  // campaign detail
  GET_CAMPAIGN_DETAIL = '/v3/campaign/detail',
  GET_CAMPAIGN_OBJECT_LOG = '/v3/campaign/detail/object_change_log',
  GET_PERFORMER_LIST = '/v3/campaign/detail/performed_by_list',
  // remove keywords
  APAC_REMOVE_KEYWORD_ACTION = '/v2/external/apac/action_remove_shopee_keyword/trigger',
  APAC_REMOVE_KEYWORD_STATUS = '/v2/external/apac/action_remove_shopee_keyword/check_status',
  // strategy
  ASSIGNED_STRATEGY_LIST = '/automation_manager/strategies',
  GET_STORE_LIST = '/automation_manager/store',
  GET_RULE_LIST = '/automation_manager/rules',
  CREATE_STRATEGY = '/v2/automation_manager/strategy/create',
  EDIT_STRATEGY = '/v2/automation_manager/strategy/update',
  VALIDATE_STRATEGY = '/v2/automation_manager/strategy/validate_create',
  UPDATE_VALIDATE_STRATEGY = '/v2/automation_manager/strategy/validate_update',
  GET_STRATEGY_DETAIL = '/automation_manager/strategy',
  GET_STRATEGY_EVENT = '/automation_manager/strategy/events',
  GET_STRATEGY_EVENT_V2 = 'v2/automation_manager/strategy/execution_ad_events',
  GET_CAMPAIGN_SKU = '/v2/automation_manager/strategy/campaign_sku_list',
  UPDATE_STRATEGY_STATUS = '/automation_manager/strategy/execution',
  GET_SPECIFIC_LIST = 'v2/automation_manager/campaign_sku_metric_list',
  GET_SPECIFIC_KEYWORD_LIST = '/v2/automation_manager/keyword_metric_list',
  GET_SPECIFIC_DISCOVERY_LOCATION_LIST = '/v2/automation_manager/discovery_location_metric_list',
  GET_SPECIFIC_PRODUCT_LIST = '/v2/automation_manager/product_metric_list',
  GET_FILTER_STRATEGY = '/automation_manager/strategy/filter_assigned_list',
  RETRY_STRATEGY_FAILED_ACTION = '/automation_manager/strategy/event/retry',
  GET_FILTER_KEYWORD_LIST = '/v2/automation_manager/strategy/keyword_event_list',
  DISMISS_ERROR = '/automation_manager/dismiss_error_strategy',
  ADS_LIST = '/v2/automation_manager/strategy/ads_list',
  PRODUCT_LIST = '/v2/automation_manager/strategy/product_event_list',
  DISPLAY_WARNING_STRATEGY = '/automation_manager/display_warning_strategy',
  CHECK_ACTIVE_STRATEGY = '/v2/automation_manager/strategy/validate_change_status',
  GET_STRATEGY_TEMPLATE = '/strategy_template/templates',
  GET_EXECUTION_LOG = '/v3/automation_manager/strategy/execution_logs',
  GET_EXECUTION_DETAIL_INFO = '/automation_manager/strategy/execution_detail_info',
  GET_EXECUTION_EVENTS = '/v2/automation_manager/strategy/execution_ad_events',
  GET_EXECUTION_KEYWORD_EVENTS = '/v2/automation_manager/strategy/execution_keyword_events',
  GET_EXECUTION_DISCOVERY_EVENTS = '/v2/automation_manager/strategy/execution_discovery_location_events',
  GET_EXECUTION_PRODUCT_EVENTS = '/v2/automation_manager/strategy/execution_product_events',
  GET_FILTER_DISCOVERY_LIST = '/v2/automation_manager/strategy/discovery_location_event_list',
  SAVE_ASSIGN_SKU_TAG = '/v2/campaign/sku/shopee/assign-tags',
  CHANGE_BID_PRICE_KEYWORD = 'v2/external/apac/action_change_bid_price_shopee_keyword/trigger',
  CHECK_STATUS_BID_PRICE_KEYWORD = 'v2/external/apac/action_change_bid_price_shopee_keyword/check_status',
  KEYWORD_TAG_LIST = 'v2/campaign/keyword/shopee/tag-list',
  // trade desk
  TD_METRIC_CARD = '/trade_desk/card',
  ALERT_LIST = '/alert/list',
  UPDATE_ALERT_STATUS = '/alert/update/status',
  ALERT_ADD = '/alert/add',
  ALERT_CHECK_DUPLICATE = '/alert/check/duplicate',
  // user
  TMALL_STORES = '/user/stores',
  USER_STORES = '/v2/user/stores',
  STORES_SORT = '/user/stores/sort',
  CHECK_TOKEN = '/user',
  CHECK_PROFILE = '/user/profile',
  // snapshot
  SNAPSHOT_AD_TRIGGER = 'v2/external/apac/crawling_snapshot/trigger',
  SNAPSHOT_AD_STATUS = 'v2/external/apac/crawling_snapshot/check_status',
  SNAPSHOT_SHOPEE_KEYWORD_TRIGGER = 'v2/external/apac/crawling_snapshot_shopee_keyword/trigger',
  SNAPSHOT_SHOPEE_KEYWORD_STATUS = 'v2/external/apac/crawling_snapshot_shopee_keyword/check_status',
  SNAPSHOT_SHOPEE_DISCOVERY_LOCATION_TRIGGER = 'v2/external/apac/crawling_snapshot_shopee_discovery_location/trigger',
  SNAPSHOT_SHOPEE_DISCOVERY_LOCATION_STATUS = 'v2/external/apac/crawling_snapshot_shopee_discovery_location/check_status',
  // Media planning
  GET_COMPANIES = '/v3/user/companies',
  IMPORT_MONTHLY_TARGET = '/v3/media-planning/target-monthly/bulk-upload',
  IMPORT_DAILY_TARGET = '/v3/media-planning/target-daily/bulk-upload',
  EXPORT_MONTHLY_TARGET = '/v3/media-planning/target-monthly/download-template',
  EXPORT_DAILY_TARGET = '/v3/media-planning/target-daily/download-template',
  GET_MONTHLY_TARGETS = '/v3/media-planning/target-monthly/view',
  GET_DAILY_TARGETS = '/v3/media-planning/target-daily/view',
}
