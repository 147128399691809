import { commonEN, commonZH } from './common'
import { errorsEN, errorsZH } from './errors'
import { routesEN, routesZH } from './routes'
import { strategyManageEN, strategyManageZH } from './strategy-manager'
import { campaignListEN, campaignListZH } from './campaign-list'
import { dashboardEN, dashboardZH } from './dashboard'
import { tradeDeskEN, tradeDeskZH } from './trade-desk'
import { environmentEN, environmentZH } from './environment'
import { planningEN, planningZH } from './planning'

import { CommonNS } from '@/typings'

export default {
  [CommonNS.Language.EN]: {
    ...commonEN,
    ...errorsEN,
    ...routesEN,
    ...strategyManageEN,
    ...campaignListEN,
    ...dashboardEN,
    ...tradeDeskEN,
    ...environmentEN,
    ...planningEN,
  },
  [CommonNS.Language.ZH]: {
    ...commonZH,
    ...errorsZH,
    ...routesZH,
    ...strategyManageZH,
    ...campaignListZH,
    ...dashboardZH,
    ...tradeDeskZH,
    ...environmentZH,
    ...planningZH,
  },
}
