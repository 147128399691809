export namespace CommonNS {
  export enum Language {
    EN = 'en',
    ZH = 'zh-CN',
  }
  export enum LayoutRouter {
    DASHBOARD = 'dashboard-layout',
  }
  export enum DashboardRouter {
    CAMPAIGN_LISTS = 'campaign-list',
    STRATEGY_ASSIGNMENT = 'strategy-assignment',
    MEDIA_PLANNING = 'media-planning',
    TRADE_DESK = 'trade-desk',
    // PERFORMANCE = 'performance',
  }
  export enum ErrorRouter {
    FORBIDDEN = 'forbbiden',
    NOTFOUND = 'not-found',
    UNAUTHORIZED = 'Unauthorized',
  }
  export enum StrategyAssignmentRouter {
    ADD_STRATEGY = 'strategy-add',
    STRATEGY_DETAIL = 'strategy-detail',
    EDIT_STRATEGY = 'strategy-edit',
    CLONE_STRATEGY = 'clone-strategy',
    DETAIL_GENERAL = 'strategy-detail-general',
    DETAIL_ACTION = 'strategy-detail-action',
    DETAIL_LOG = 'strategy-detail-log',
    DETAIL_LOG_LIST = 'strategy-detail-log-list',
    DETAIL_LOG_DETAIL = 'strategy-detail-log-detail',
  }
  export enum TradeDeskRouter {
    STORE_OVERVIEW = 'store-overview',
    ALERT_LIST = 'alert-list',
    ADD_ALERT = 'add-alert',
    EDIT_ALERT = 'edit-alert',
    COPY_ALERT = 'copy-alert',
  }
  export enum CampaignListRouter {
    CAMPAIGN_DETAIL = 'campaign-detail',
  }

  export enum AuthenticationRouter {
    SSO_LOGIN = 'SSO-LOGIN',
  }

  // Store infos
  export interface TmallStore {
    id: string
    account: string
    sessionKey: string
    platform: string
  }
  export interface RouterItemProps {
    name: string
    path: string
    view?: string
    redirect?: string
    parent?: string | null
    children?: RouterItemProps[]
  }
  export interface ItemRouter {
    name: string
    path: string
    component?: () => Promise<any>
    meta?: any
    redirect?: {
      name: string
    }
    children?: ItemRouter[]
  }
  export interface DataList {
    rows: any[]
    total: number
  }
  export interface HeaderBreadcrumbs {
    back?: boolean
    textBold?: boolean
    name: string
    link?: string
    to?: string
    [k: string]: any
  }
  export interface HeaderProps {
    title?: string
    showTitle?: boolean
    showTooltip?: boolean
    breadcrumbs?: HeaderBreadcrumbs[]
    bordered?: boolean
    titleClass?: string
    showHeader?: boolean
    avatar?: string
  }
  export interface ContainerProps extends Omit<HeaderProps, 'bordered'> {
    isLoading?: boolean
    headerBordered?: boolean
    headerClass?: string
  }
  export interface HelpTextProps {
    icon: any
    text?: string
    tooltip?: boolean
    tooltipText?: string
    size?: number
    popperClass?: string
    center?: boolean
    placement?: 'left' | 'right'
    className?: string
    ellipsis?: boolean | number
    showText?: boolean
  }
  export interface InputTagsProps {
    type?: 'number' | 'text'
    suffix?: any
    inputProps?: any
  }
  export interface EChartInfo {
    height: number | string
    data: any[]
    showLegend?: boolean
    showTooltip?: boolean
    options?: any
    tooltipFormatter?: any
    tooltipValueFormatter?: any
    yAxisFormatter?: any
    xAxisFormatter?: any
    emptyText?: string
    autoresize?: boolean
  }
  export interface SelectChooseProps {
    title: string
    columns?: any[]
    name?: string
  }
  export interface FilterSearchProps {
    options?: any[]
    placeholder?: string
    showOptions?: boolean
  }
  export interface BaseButtonProps {
    name?: string | object
    to?: string | object
    plain?: boolean
    type?:
      | 'default'
      | 'text'
      | 'success'
      | 'warning'
      | 'info'
      | 'primary'
      | 'danger'
    iconPosition?: 'left' | 'right'
    iconSize?: number
    icon?: any
  }
  export interface BaseConditionProps {
    platform: string
    channel?: string
    store?: string
    layer?: string
    columns?: string[]
    countryCode?: string
    name?: string
    groupIndex?: number
    dataFilters?: any
  }

  export interface ConditionItemProps {
    field: string
    operator: string
    value: any
    label?: string
    type?: string
    expression?: string
    postFix?: string
    componentType?: string
    componentAttrs?: any
  }
  export interface AdvancedFilterProps {
    platform: string
    channel?: string
    store?: string
    layer: string
    groups: Array<{
      title: string
      name: string
      helpText?: string
      conditions?: ConditionItemProps[]
    }>
    countryCode: string
    dataFilters?: any
  }
  export interface DynamicInputProps {
    min?: number
    max?: number
    placeholder?: string
    keyField?: string
    btnName?: string
    btnProps?: any
    message?: string
    onCreate?: any
    onRemove?: any
  }
  export interface MediaPageProps {
    name?: string
    loadData?: any
    dataParams?: any
  }
  export interface MediaLayoutProps extends MediaPageProps {
    initData?: any
  }
  export interface MediaDropdownProps extends MediaPageProps {
    listData: CommonNS.DataList
    valueExpr?: string
  }
  export interface BaseImportDataProps {
    steps: any
    urlImport: API
    urlExport: API
    allowAccept?: string
    allowUpdateError?: string
    onSuccess?: any
    importProps?: any
    exportProps?: any
    columns?: any
    checkFile?: any
    exportType?: any
  }
  export interface MediaDialogProps {
    type: string
    buttons?: any
    keywords?: any
    showCancel?: boolean
  }
  export interface RadioListItem {
    label: string
    value: string
    icon: string
    disabled?: boolean
    className?: string
    attrs?: Record<string, any>
  }
  export interface RadioListProps {
    options: RadioListItem[]
  }
  export interface CheckBoxItem {
    label: string
    value: string
    disabled?: boolean
    checked?: boolean
    indeterminate?: boolean
    attrs?: Record<string, any>
  }
  export interface BaseCheckBoxProps {
    options: CheckBoxItem[]
    type?: 'border' | 'button'
  }
  export interface StepLayoutProps {
    title: string
    helpText: string
    isEditMode?: boolean
    avatar?: string
  }
  export interface RefreshDataProps {
    text: string
    refreshCallback?: any
    checkRefreshCallback?: any
    dataCallback?: any
    timeout?: number
    refreshParams?: any
    showButton?: boolean
  }
  export interface InfoTextProps {
    text: string
    tooltip?: string
    icon?: any
    center?: boolean
    ellipsis?: boolean
  }
  export interface CloseButtonProps {
    tooltip?: string
    icon?: any
  }
  export interface BaseAlertProps {
    type?: string
    title?: string
    content?: string
    closable?: boolean
    showIcon?: boolean
    icon?: any
  }
  export interface AutocompleteProps {
    url?: API
    params?: any
    displayExpr?: string
    data?: any[]
    valueKey?: string
    valueExpr?: string
  }
}
