import { map, pick, sortBy } from 'lodash-es'
import axios from './axios'
import { API, http } from '.'
import type { CommonNS, MetricNS } from '@/typings'
import { useAssignedStrategyStore } from '@/store/module/assigned-strategy'
import type { MethodEnum } from '@/typings/api'

export const getProjectConfig = () => axios.get(API.PROJECT_CONFIG)

export const activityTrackUserLogin = async (page: string) => {
  await http.post({
    api: API.ACTIVITY_TRACK,
    data: {
      action: 'user_login',
      page,
    },
  })
}

export const postTmallStores = async () => {
  try {
    const list = (
      await http.post({
        api: API.TMALL_STORES,
      })
    ).stores as CommonNS.TmallStore[]
    return list.filter(item => !!item.sessionKey)
  } catch (e) {
    console.error('fetch tmall stores error:', e)
    return []
  }
}

export const postStoresSort = async (stores: CommonNS.TmallStore[]) => {
  try {
    await http.post({
      api: API.STORES_SORT,
      data: {
        stores: stores.map(item => ({
          id: item.id,
          platform: item.platform,
        })),
      },
    })
    return true
  } catch (e) {
    console.error('Sort stores error:', e)
    return false
  }
}

export const postMetricList = async (config: {
  platform: MetricNS.PLATFORM
  channel: MetricNS.CHANNEL
  scene: MetricNS.SCENE
  page: string
}) => {
  try {
    return (
      await http.post({
        api: API.METRIC_LIST,
        data: config,
      })
    ).records as MetricNS.MetricConfig[]
  } catch (e) {
    console.error('fetch metric configs error:', e)
    return []
  }
}

export const fetchAllStores = async () => {
  try {
    const strategyStore = useAssignedStrategyStore()
    const mapDataStore = (data: any) =>
      map(data, item => ({
        key: item.shopId,
        label: item.shopName,
        ...pick(item, ['platform', 'countryCode', 'version']),
      }))
    if (strategyStore.stores.length === 0) {
      return await http.get({ api: API.GET_STORE_LIST }).then(data => {
        strategyStore.setStoreAssignedList(sortBy(data.rows, ['shopName']))
        return mapDataStore(strategyStore.stores)
      })
    }
    return Promise.all([strategyStore.stores]).then(data =>
      mapDataStore(data[0]),
    )
  } catch (e) {
    console.error('fetch store error:', e)
    throw e
  }
}
export const exportFileData = async (option: any, params: any) => {
  try {
    return await http[option.method as MethodEnum]({
      api: option.action,
      data: params,
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
    })
  } catch (e) {
    console.error('export template campaign sku error:', e)
    throw e
  }
}
export const uploadFileData = async (option: any, params: any) => {
  try {
    return await http[option.method as MethodEnum]({
      api: option.action,
      data: params,
      onUploadProgress: (process: any) => {
        if (process.total > 0) {
          process.percent = (process.loaded / process.total) * 100
        }
        option.onProgress(process)
      },
    })
  } catch (e) {
    console.error('upload file error:', e)
    throw e
  }
}
