<template>
  <n-dialog-provider>
    <el-config-provider :locale="elementLocale">
      <n-config-provider
        :locale="naiveLocale.lang"
        :date-locale="naiveLocale.date"
        :theme-overrides="theme"
      >
        <n-message-provider>
          <Maintenance v-if="isMaintenanceMode && !canBypassMaintenance" />
          <Unavailable v-else-if="isUnavailableMode && !canBypassMaintenance" />
          <router-view v-else />
        </n-message-provider>
      </n-config-provider>
    </el-config-provider>
  </n-dialog-provider>
</template>

<script setup lang="ts">
import { dateEnUS, dateZhCN, enUS, zhCN } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import en from 'element-plus/es/locale/lang/en'
import { merge } from 'lodash-es'
import { CommonNS } from '@/typings'
import themeOverrides from '@/configs/n-theme-overrides'
import { useAuth0Store, useCommonStore } from '@/store'
import Maintenance from './components/widgets/Maintenance.vue'
import Unavailable from './components/widgets/Unavailable.vue'

const { locale } = useI18n()
const commonStore = useCommonStore()
const auth0Store = useAuth0Store()

const isAuthenticated = ref(
  auth0Store.accountInfo.email === null || auth0Store.accountInfo.email === '',
)

const isMaintenanceMode = ref(
  import.meta.env.VITE_MAINTENANCE_MODE === '1' && !isAuthenticated.value,
)

const isUnavailableMode = ref(
  import.meta.env.VITE_MAINTENANCE_MODE === '2' && !isAuthenticated.value,
)

locale.value = CommonNS.Language.EN
// const elementLocale = computed(() =>
//   locale.value === CommonNS.Language.EN ? en : zhCn,
// )

// const isMaintenanceMode = computed(
//   () => import.meta.env.VITE_MAINTENANCE_MODE === '1' && !isAuthenticated.value,
// )
// const isUnavailableMode = computed(
//   () => import.meta.env.VITE_MAINTENANCE_MODE === '2' && !isAuthenticated.value,
// )

// Check if the user can bypass maintenance
const canBypassMaintenance = computed(() => {
  const userEmail = auth0Store.accountInfo.email ?? null
  if (!userEmail || userEmail === '') return false
  const allowedEmails =
    import.meta.env.VITE_BYPASS_MAINTENANCE_USERS?.split(',') || []
  return allowedEmails.includes(userEmail)
})
const elementLocale = computed(() => en)
const naiveLocale = computed(() =>
  locale.value === CommonNS.Language.EN
    ? {
        lang: merge(enUS, {
          Pagination: { goto: en.el.pagination.goto },
          DataTable: { clear: 'Cancel' },
        }),
        data: dateEnUS,
      }
    : {
        lang: zhCN,
        date: dateZhCN,
      },
)
const theme = computed(() =>
  merge(themeOverrides, commonStore.customNTheme || {}),
)

watchEffect(() => {
  isMaintenanceMode.value =
    import.meta.env.VITE_MAINTENANCE_MODE === '1' && !isAuthenticated.value
  isUnavailableMode.value =
    import.meta.env.VITE_MAINTENANCE_MODE === '2' && !isAuthenticated.value
})
</script>

<style lang="scss">
.common-hyperlink-style {
  color: #0e2fb4 !important;
  font-weight: 440 !important;
  &:hover {
    color: #1c55fb !important;
    font-size: 13px !important;
    font-weight: 520 !important;
    line-height: 20px !important;
  }
}
</style>
