import { defineStore } from 'pinia'
import { get, has, map, set, sortBy, unionBy } from 'lodash-es'
import _i18n from '@/plugins/i18n'
import type { CommonNS } from '@/typings'
import { Common } from '@/typings'
import { convertObjectSnakeToCamel } from '@/utils/util'
import { CHANNEL_TABNAME } from '@/constants/common'

export const useCommonStore = defineStore(Common.name, {
  state: (): Common.State => ({
    locale: _i18n.global.locale.value as CommonNS.Language,
    expandSidebar: true,
    renderAllowed: false,
    customNTheme: {},
    storeList: {
      rows: [],
      total: 0,
    },
    companyList: {
      rows: [],
      total: 0,
    },
    strategyData: {
      stores: [],
      creators: [],
    },
    cacheData: {},
  }),
  actions: {
    switchLang(lang: CommonNS.Language) {
      this.locale = lang
      _i18n.global.locale.value = lang
    },
    setDataList(name: string, data: CommonNS.DataList) {
      const resData = get(this, name)
      if (has(resData, 'rows')) {
        resData.rows = unionBy(resData.rows, data.rows, 'id')
        resData.rows = sortBy(
          resData.rows.map((row: any) => convertObjectSnakeToCamel(row)),
          'name',
        )
        resData.total = data.total
      } else {
        set(this, name, data)
      }
    },
    setCacheItem(key: string, value: any) {
      set(this.cacheData, key, value)
    },
  },
  getters: {
    isShowComponent: (state: any) => (name?: string) =>
      name ? state[name]?.rows?.length > 0 : true,
    isShowAuthorizePage: (state: any) => (name?: string) =>
      name ? state[name]?.rows?.length === 0 : false,
    getDataList: (state: any) => (name?: string) => (name ? state[name] : []),
    findStoreById: (state: any) => (id: any) =>
      state.storeList.rows.find((store: any) => store.id === id),
    findStoreInfoById: (state: any) => (id: any) => {
      const storeInfo = state.storeList.rows.find((item: any) => item.id === id)
      return {
        ...storeInfo,
        channels: map(storeInfo?.channels, channel => ({
          ...convertObjectSnakeToCamel(channel),
          tabName: CHANNEL_TABNAME[channel.key_name],
        })),
      }
    },
    findCompanyById: (state: any) => (id: any) =>
      state.companyList.rows.find((company: any) => company.id === id),
    findCompanyByIdSlug: (state: any) => (id: any) =>
      state.companyList.rows.find((company: any) => company.idSlug === id),
    getCacheItem: (state: any) => (key: string) => get(state.cacheData, key),
  },
})
