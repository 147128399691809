import path from 'path'
import { CommonNS } from '@/typings'

export default [
  {
    path: '/',
    meta: { title: 'Retail Media' },
    redirect: { name: CommonNS.LayoutRouter.DASHBOARD },
  },
  {
    name: CommonNS.LayoutRouter.DASHBOARD,
    path: '/dashboard',
    meta: { title: 'Retail Media' },
    component: () => import('@/layouts/DashboardLayout.vue'),
    redirect: {
      name: CommonNS.DashboardRouter.CAMPAIGN_LISTS,
    },
    children: [
      // {
      //   name: CommonNS.DashboardRouter.TRADE_DESK,
      //   path: CommonNS.DashboardRouter.TRADE_DESK,
      //   meta: { parent: null },
      //   component: () => import('@/views/dashboards/TradeDeskIndex.vue'),
      //   redirect: {
      //     name: CommonNS.TradeDeskRouter.STORE_OVERVIEW,
      //   },
      //   children: [
      //     {
      //       name: CommonNS.TradeDeskRouter.STORE_OVERVIEW,
      //       path: CommonNS.TradeDeskRouter.STORE_OVERVIEW,
      //       component: () =>
      //         import(
      //           '@/views/dashboards/modules/trade-desk/layouts/TradeDeskStoreOverview.vue'
      //         ),
      //     },
      //     {
      //       name: CommonNS.TradeDeskRouter.ALERT_LIST,
      //       path: CommonNS.TradeDeskRouter.ALERT_LIST,
      //       component: () =>
      //         import(
      //           '@/views/dashboards/modules/trade-desk/layouts/TradeDeskConfigCenter.vue'
      //         ),
      //     },
      //     // {
      //     //   name: CommonNS.TradeDeskRouter.ADD_ALERT,
      //     //   path: CommonNS.TradeDeskRouter.ADD_ALERT,
      //     //   component: () =>
      //     //     import(
      //     //       '@/views/dashboards/modules/trade-desk/layouts/TradeDeskAlertForm.vue'
      //     //     ),
      //     // },
      //     // {
      //     //   name: CommonNS.TradeDeskRouter.EDIT_ALERT,
      //     //   path: `${CommonNS.TradeDeskRouter.EDIT_ALERT}/:id`,
      //     //   component: () =>
      //     //     import(
      //     //       '@/views/dashboards/modules/trade-desk/layouts/TradeDeskAlertForm.vue'
      //     //     ),
      //     // },
      //     // {
      //     //   name: CommonNS.TradeDeskRouter.COPY_ALERT,
      //     //   path: `${CommonNS.TradeDeskRouter.COPY_ALERT}/:id`,
      //     //   component: () =>
      //     //     import(
      //     //       '@/views/dashboards/modules/trade-desk/layouts/TradeDeskAlertForm.vue'
      //     //     ),
      //     // },
      //   ],
      // },
      {
        name: `${CommonNS.DashboardRouter.CAMPAIGN_LISTS}-layout`,
        path: CommonNS.DashboardRouter.CAMPAIGN_LISTS,
        meta: { parent: null },
        component: () => import('@/views/dashboards/CampaignListIndex.vue'),
        redirect: {
          name: CommonNS.DashboardRouter.CAMPAIGN_LISTS,
        },
        children: [
          {
            name: CommonNS.DashboardRouter.CAMPAIGN_LISTS,
            path: ':keyId?/:tabName?/:param?',
            props: (route: any) => ({
              param: route.params.param,
              queryParam: route.query.param,
            }),
            component: () =>
              import('@/views/dashboards/modules/campaign-list/Index.vue'),
          },
          {
            name: CommonNS.CampaignListRouter.CAMPAIGN_DETAIL,
            path: ':keyId/:tabName/object-log/:id',
            component: () =>
              import('@/views/dashboards/modules/campaign-list/Detail.vue'),
          },
        ],
      },
      {
        name: `${CommonNS.DashboardRouter.MEDIA_PLANNING}-layout`,
        path: CommonNS.DashboardRouter.MEDIA_PLANNING,
        meta: { parent: null },
        component: () => import('@/views/dashboards/MediaPlanningIndex.vue'),
        redirect: {
          name: CommonNS.DashboardRouter.MEDIA_PLANNING,
        },
        children: [
          {
            name: CommonNS.DashboardRouter.MEDIA_PLANNING,
            path: ':keyId?/:tabName?/:param?',
            props: (route: any) => ({
              param: route.params.param,
              queryParam: route.query.param,
            }),
            meta: { parent: null, title: 'Media planning | Retail Media' },
            component: () =>
              import('@/views/dashboards/modules/media-planning/Index.vue'),
          },
        ],
      },
      {
        name: `${CommonNS.DashboardRouter.STRATEGY_ASSIGNMENT}-layout`,
        path: CommonNS.DashboardRouter.STRATEGY_ASSIGNMENT,
        meta: { parent: null },
        component: () =>
          import('@/views/dashboards/StrategyAssignmentIndex.vue'),
        redirect: {
          name: CommonNS.DashboardRouter.STRATEGY_ASSIGNMENT,
        },
        children: [
          {
            name: CommonNS.DashboardRouter.STRATEGY_ASSIGNMENT,
            path: '',
            meta: { parent: null, title: 'Strategies | Retail Media' },
            component: () =>
              import(
                '@/views/dashboards/modules/strategy-assignment/index.vue'
              ),
          },
          {
            name: CommonNS.StrategyAssignmentRouter.ADD_STRATEGY,
            path: 'add',
            meta: { parent: null, title: 'Add new strategy | Retail Media' },
            component: () =>
              import('@/views/dashboards/modules/strategy-assignment/Add.vue'),
          },
          {
            name: CommonNS.StrategyAssignmentRouter.STRATEGY_DETAIL,
            path: 'detail/:id',
            component: () =>
              import(
                '@/views/dashboards/modules/strategy-assignment/Detail.vue'
              ),
            redirect: {
              name: CommonNS.StrategyAssignmentRouter.DETAIL_GENERAL,
            },
            children: [
              {
                name: CommonNS.StrategyAssignmentRouter.DETAIL_GENERAL,
                path: '',
                component: () =>
                  import(
                    '@/views/dashboards/modules/strategy-assignment/details/General.vue'
                  ),
                meta: { name: 'general' },
              },
              {
                name: CommonNS.StrategyAssignmentRouter.DETAIL_ACTION,
                path: 'action',
                component: () =>
                  import(
                    '@/views/dashboards/modules/strategy-assignment/details/ExecutedActions.vue'
                  ),
                meta: { name: 'executed' },
              },
              {
                name: CommonNS.StrategyAssignmentRouter.DETAIL_LOG,
                path: 'log',
                component: () =>
                  import(
                    '@/views/dashboards/modules/strategy-assignment/execution-log/ExecutionLog.vue'
                  ),
                meta: { name: 'execution-log' },
                redirect: {
                  name: CommonNS.StrategyAssignmentRouter.DETAIL_LOG_LIST,
                },
                children: [
                  {
                    name: CommonNS.StrategyAssignmentRouter.DETAIL_LOG_LIST,
                    path: '',
                    component: () =>
                      import(
                        '@/views/dashboards/modules/strategy-assignment/execution-log/ExecutionLogList.vue'
                      ),
                    meta: { name: 'execution-log' },
                  },
                  {
                    name: CommonNS.StrategyAssignmentRouter.DETAIL_LOG_DETAIL,
                    path: ':time/:rule',
                    component: () =>
                      import(
                        '@/views/dashboards/modules/strategy-assignment/execution-log/ExecutionLogDetail.vue'
                      ),
                    meta: { name: 'execution-log' },
                  },
                ],
              },
            ],
          },
          {
            name: CommonNS.StrategyAssignmentRouter.EDIT_STRATEGY,
            path: 'edit/:id',
            meta: { parent: null, title: 'Edit a strategy | Retail Media' },
            component: () =>
              import('@/views/dashboards/modules/strategy-assignment/Edit.vue'),
          },
          {
            name: CommonNS.StrategyAssignmentRouter.CLONE_STRATEGY,
            path: 'clone/:id/:storeId',
            meta: { parent: null, title: 'Clone a strategy | Retail Media' },
            component: () =>
              import('@/views/dashboards/modules/strategy-assignment/Add.vue'),
          },
        ],
      },
    ],
  },
]
